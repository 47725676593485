@import (less) 'imports.less';

.sc-timeline-marker {
    position: relative;
    border-bottom: 1px solid @token-global--color-blue-br-300;

    &::before {
        position: absolute;
        content: '';
        width: 0; 
        height: 0; 
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid @token-global--color-blue-br-300;
    }
    
    .sc-tooltip {
        position: absolute;
        background: @token-global--color-blue-br-600;
        left: 10px;
        color:  @token-global--text-color-white;
        padding: 0 5px;
        font-size: 12px;
        border-radius: @token-global--border-radius-md;
        bottom: 100%;
        margin-bottom: 5px;
        left: 5px;

        &::before{
            position: absolute;
            content: '';
            width: 0; 
            height: 0;
            top: 100%;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid @token-global--color-blue-br-600;
        }

    }
}

.schedule {min-height: 100vh;}

.sc__column{
    border-right: 1px solid @token-global--color-gray-300;
}
.sc__column:first-child {
    width: 40%;
}

.sc__column:last-child {
    width: 60%;
    background: rgba(145, 199, 239, 0.05)
}

.sc__column__title {
    padding-left: 50px;

    .sc__column { border: 0;}
}

.sc__scrollable-content {
    overflow-x: scroll;
}

.sc__column__events {
    min-width: 500px;
}


//event-item states
.col {
    cursor: pointer;
}

.col, .row {
    padding: 0 2px;

    h4 {
        margin: 0;
    }
}

.sc__event-item__status {display: none;}

.sc__event-item.live {
    border-color: @token-global--color-red-700;
    background: #fff4f4!important;

    .sc__event-item__status {display: flex;}
    .sc__event-item__time {color: @token-global--color-red-700;}
}

.sc__event-item.scheduled {
    background: @token-global--color-gray-50;
}

.sc__event-item.available {
    background: @token-global--color-blue-br-50;
    border-color: @token-global--color-blue-br-300;
}

.sc__event-item.tiny-item-height {
    h4 {
        white-space: nowrap;
        display: inline;
        margin-right: 5px;
    }
}
.sc__event-item.small-item-height {
    div {
        //hide PopUp for Icon
        flex-direction: row-reverse;
        align-items: center;
    }

    h4 {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
    }

    .sc__event-item__top-row {margin-right: 3px;}
    .sc__event-item__time, .sc__event-item__status, .sc__event-item__bottom-row {
        display: none;
    }
}


//END event-item states

.sc__event-item {
    border: 1px solid @token-global--color-gray-300;
    border-radius: @token-global--border-radius-md;
    background: white;

    > div { padding: 0.2rem 0.4rem; }

    i {
        color: @token-global--color-black-100;
        display: flex;
        align-items: center;
    }

    h4 {
        //margin: 0;
        font-size: @token-global--font-size-700;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        //min-height: 2.5rem;
    }

    svg {
        height: 15px;
        width: 15px;
        background: rgb(182, 182, 182);
    }
}

.sc__scrollable-area {
    //overflow-y: scroll;
    position: relative;
}

.sc__all-columns {
    position: absolute;
    left: 0;
    right: 0;
    padding-left: 50px;
}

.sc__event-item__time, .sc__event-item__meta, .sc__event-item__status {
    font-size: @token-global--font-size-500;
}

.sc__event-item__meta {
    padding-right: 10px;

    i {
        padding-right: 2px;
        margin-top: 1px;
    }
    span:not(:last-child) {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    span {
        line-height: 15px;
        &:after {
            padding: 0 @token-global--padding-x-sm;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.sc__event-item__status {
    padding: 0 @token-global--padding-x-md;
}

.sc__event-item__icons, .buttons__switch  {
    font-size: @token-global--font-size-900;
}

.sc__event-item__icons.colored-bg {
    padding: 3px;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: -2px;
    background: var(--sc-action-bgcolor);
    
    i { color: var(--sc-action-color); }
}

:root {
    --sc-action-color: @token-global--color-orange-600;
    --sc-action-bgcolor: @token-global--color-orange-100;
    //--sc-action-bgcolor: rgba(250, 141, 36, 0.2);
}

//highlight orange

//event-legend
//stream-break yellow
.sc-event-stream-break{
    --sc-action-color: @token-global--color-yellow-sd-1200;
    --sc-action-bgcolor: @token-global--color-yellow-sd-800;
}
//data only blue
.sc-event-data-only{
    --sc-action-color: @token-global--color-blue-br-700;
    --sc-action-bgcolor: @token-global--color-blue-br-100;
}
//no betting
.sc-event-no-betting{
    --sc-action-color: @token-global--color-navy-sr-500;
    --sc-action-bgcolor: @token-global--color-navy-sr-100;
    position: relative;

    &:after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 1px;
        background: var(--sc-action-color);
        width: 100%;
        top: 50%;
        transform: rotate(-45deg);
    }
}


//stared
.highlighted_stared {
    --sc-action-color: #cea900;
    --sc-action-bgcolor: @token-global--color-yellow-sd-200;
}

.sc__column__time {
    font-size: @token-global--font-size-600;
    position: relative;

    &:before, &:after {
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        content: '';
        border:none;
        color:#fff;
        background-color:#fff;
    }
    &:before {
        bottom: 0;
        border-top: 1px solid @token-global--color-gray-200;
    }

    &:after {
        top: 50%;
        border-top: 1px dashed @token-global--color-gray-200;
    }
}

.sc__column__time__bg {
    height: 100%;
    background: @token-global--color-gray-50;
    width: 50px;
    padding: @token-global--padding-y-sm @token-global--padding-x-md;
}

.filter-active {
    border-color: #779be7;
    box-shadow: 0 0px 5px #779be7;
}

.filter-inactive {
    opacity: 0.8;
}