@font-face {
  font-family: 'Sportradar16-5-0';
  src: url('@sr-design-system/ds-tokens-global-icons/fonts/Sportradar16-5-0.ttf?465ndi') format('truetype'), url('@sr-design-system/ds-tokens-global-icons/fonts/Sportradar16-5-0.woff?465ndi') format('woff'), url('@sr-design-system/ds-tokens-global-icons/fonts/Sportradar16-5-0.svg?465ndi#Sportradar16-5-0') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Sportradar16-5-0' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-592-filter-funnel--core--interface-:before {
  content: "\eb4e";
}
.icon-591-document-download--core--document-:before {
  content: "\eb4d";
}
.icon-590-virus-bacteria--misc-:before {
  content: "\eb4c";
}
.icon-589-golf-hole--misc-:before {
  content: "\eb4b";
}
.icon-588-broom-clean--misc-:before {
  content: "\eb4a";
}
.icon-587-success-sm-solid--interface-:before {
  content: "\eb49";
}
.icon-586-success-sm-outline--interface-:before {
  content: "\eb48";
}
.icon-585-checkbox-selected-outline-sm--interface-:before {
  content: "\eb47";
}
.icon-584-checkbox-selected-outline-md--interface-:before {
  content: "\eb46";
}
.icon-583-bitcoin-coin--misc-:before {
  content: "\eb45";
}
.icon-582-ethereum-logo--misc-:before {
  content: "\eb44";
}
.icon-581-bitcoin-outline--misc-:before {
  content: "\eb43";
}
.icon-580-bitcoin-sign--misc-:before {
  content: "\eb42";
}
.icon-579-wdl--arrow--betting-:before {
  content: "\eb41";
}
.icon-578-checkbox-indeterminate-sm--interface-:before {
  content: "\eb40";
}
.icon-577-checkbox-indeterminate-md--interface-:before {
  content: "\eb3f";
}
.icon-576-video-stream-off--media-:before {
  content: "\eb3e";
}
.icon-575-video-stream--media-:before {
  content: "\eb3d";
}
.icon-574-alarm-disabled--core--interface--alert-:before {
  content: "\eb3c";
}
.icon-573-alarm-solid-disabled--core--interface--alert-:before {
  content: "\eb3b";
}
.icon-572-minus-sm--core--interface-:before {
  content: "\eb3a";
}
.icon-571-plus-sm--core--interface-:before {
  content: "\eb39";
}
.icon-570-menu-tree-end--interface-:before {
  content: "\eb38";
}
.icon-569-menu-tree-midle--interface-:before {
  content: "\eb37";
}
.icon-568-arrow-down-solid--arrow-:before {
  content: "\eb36";
}
.icon-567-arrow-up-solid--arrow-:before {
  content: "\eb35";
}
.icon-566-coins-money--core--interface--misc-:before {
  content: "\eb34";
}
.icon-565-rows-empty--interface-:before {
  content: "\eb33";
}
.icon-564-collumns-empty--interface-:before {
  content: "\eb32";
}
.icon-563-rows--interface-:before {
  content: "\eb31";
}
.icon-562-collumns--interface-:before {
  content: "\eb30";
}
.icon-561-euro-2-outline--misc-:before {
  content: "\eb2f";
}
.icon-560-dollar-2-outline--misc-:before {
  content: "\eb2e";
}
.icon-559-money--core--interface--misc-:before {
  content: "\eb2d";
}
.icon-558-spinner--interface--media-:before {
  content: "\eb2c";
}
.icon-557-selfie--interface--social--media--user-:before {
  content: "\eb2b";
}
.icon-556-arrow-down-sm--arrow-:before {
  content: "\eb2a";
}
.icon-555-arrow-up-sm--arrow-:before {
  content: "\eb29";
}
.icon-554-wrestling--sport-:before {
  content: "\eb28";
}
.icon-553-world-lottery--sport-:before {
  content: "\eb27";
}
.icon-552-weightlifting--sport-:before {
  content: "\eb26";
}
.icon-551-swimming--sport-:before {
  content: "\eb25";
}
.icon-550-surfing--sport-:before {
  content: "\eb24";
}
.icon-549-sumo--sport-:before {
  content: "\eb23";
}
.icon-548-speed-skating--winter-sport--sport-:before {
  content: "\eb22";
}
.icon-547-specials--sport-:before {
  content: "\eb21";
}
.icon-546-skeleton--winter-sport--sport-:before {
  content: "\eb20";
}
.icon-545-short-track-speed-skating--winter-sport--sport-:before {
  content: "\eb1f";
}
.icon-544-shooting--sport-:before {
  content: "\eb1e";
}
.icon-543-sepak-takraw--sport-:before {
  content: "\eb1d";
}
.icon-542-sailing--sport-:before {
  content: "\eb1c";
}
.icon-541-rowing--sport-:before {
  content: "\eb1b";
}
.icon-540-rink-hockey--sport-:before {
  content: "\eb1a";
}
.icon-539-polo--sport-:before {
  content: "\eb19";
}
.icon-538-padel--sport-:before {
  content: "\eb18";
}
.icon-537-olympics--sport-:before {
  content: "\eb17";
}
.icon-536-olympics-youth--sport-:before {
  content: "\eb16";
}
.icon-535-netball--sport-:before {
  content: "\eb15";
}
.icon-534-lacrosse--sport-:before {
  content: "\eb14";
}
.icon-533-judo--sport-:before {
  content: "\eb13";
}
.icon-532-inline-hockey--sport-:before {
  content: "\eb12";
}
.icon-531-horseball--sport-:before {
  content: "\eb11";
}
.icon-530-horse-racing--sport-:before {
  content: "\eb10";
}
.icon-529-gymnastics--sport-:before {
  content: "\eb0f";
}
.icon-528-greyhounds--sport-:before {
  content: "\eb0e";
}
.icon-527-gaelic--sport-:before {
  content: "\eb0d";
}
.icon-526-formula-1--sport-:before {
  content: "\eb0c";
}
.icon-525-figure-skating--winter-sport--sport-:before {
  content: "\eb0b";
}
.icon-524-diving--sport-:before {
  content: "\eb0a";
}
.icon-523-canoeing--sport-:before {
  content: "\eb09";
}
.icon-522-beach-tennis--sport-:before {
  content: "\eb08";
}
.icon-521-archery--sport-:before {
  content: "\eb07";
}
.icon-520-radiobutton-selected-sm--interface-:before {
  content: "\eb06";
}
.icon-519-radiobutton-unselected-sm--interface-:before {
  content: "\eb05";
}
.icon-518-radiobutton-selected-md--interface-:before {
  content: "\eb04";
}
.icon-517-radiobutton-unselected-md--interface-:before {
  content: "\eb03";
}
.icon-516-checkbox-selected-sm--interface-:before {
  content: "\eb02";
}
.icon-515-checkbox-unselected-sm--interface-:before {
  content: "\eb01";
}
.icon-514-checkbox-selected-md--interface-:before {
  content: "\eb00";
}
.icon-513-checkbox-unselected-md--interface-:before {
  content: "\eaff";
}
.icon-512-kabaddi--sport-:before {
  content: "\eafe";
}
.icon-511-card-3-betting-:before {
  content: "\eafd";
}
.icon-510-card-2--betting-:before {
  content: "\eafc";
}
.icon-509-gaelic-hurling--sport-:before {
  content: "\eafb";
}
.icon-508-gaelic-football--sport-:before {
  content: "\eafa";
}
.icon-507-esport--sport-:before {
  content: "\eaf9";
}
.icon-506-field-hockey--sport-:before {
  content: "\eaf8";
}
.icon-505-box--sport-:before {
  content: "\eaf7";
}
.icon-504-chess--sport-:before {
  content: "\eaf6";
}
.icon-503-street-hockey--sport-:before {
  content: "\eaf5";
}
.icon-502-bowls--sport-:before {
  content: "\eaf4";
}
.icon-501-card-1--betting-:before {
  content: "\eaf3";
}
.icon-500-athletics--sport-:before {
  content: "\eaf2";
}
.icon-499-basketball-3x3--sport-:before {
  content: "\eaf1";
}
.icon-498-mma-fighting--sport-:before {
  content: "\eaf0";
}
.icon-497-soccer-mythical--sport-:before {
  content: "\eaef";
}
.icon-496-snowboard--winter-sport--sport-:before {
  content: "\eaee";
}
.icon-495-snowboard--winter-sport--sport-:before {
  content: "\eaed";
}
.icon-494-ski-nordic-combined--winter-sport--sport-:before {
  content: "\eaec";
}
.icon-493-ski-jump--winter-sport--sport-:before {
  content: "\eaeb";
}
.icon-492-ski-freestyle--winter-sport--sport-:before {
  content: "\eaea";
}
.icon-491-ski-cross-country--winter-sport--sport-:before {
  content: "\eae9";
}
.icon-490-ski-biathlon--winter-sport--sport-:before {
  content: "\eae8";
}
.icon-489-bobsleigh--winter-sport--sport-:before {
  content: "\eae7";
}
.icon-488-luge--winter-sport--sport-:before {
  content: "\eae6";
}
.icon-487-ski-alpine--winter-sport-sport-:before {
  content: "\eae5";
}
.icon-486-motor-sports--sport-:before {
  content: "\eae4";
}
.icon-485-winter-sports--sport-:before {
  content: "\eae3";
}
.icon-484-squash--sport-:before {
  content: "\eae2";
}
.icon-483-beach-soccer--sport-:before {
  content: "\eae1";
}
.icon-482-alarm-2-solid--core--interface--alert-:before {
  content: "\ea93";
}
.icon-481-alarm-2--core--interface--alert-:before {
  content: "\ea92";
}
.icon-480-rss-2--core--social-:before {
  content: "\ea91";
}
.icon-479-share--core--social-:before {
  content: "\ea90";
}
.icon-478-linkedin-2--core--social-:before {
  content: "\ea8f";
}
.icon-477-instagram-2--core--social-:before {
  content: "\ea8e";
}
.icon-476-windows-2--core--social-:before {
  content: "\ea8d";
}
.icon-475-android-2--core--social-:before {
  content: "\ea8c";
}
.icon-474-apple-2--core--social-:before {
  content: "\ea8b";
}
.icon-473-twitter-2--core--social-:before {
  content: "\ea8a";
}
.icon-472-paypal-2--core--social-:before {
  content: "\ea89";
}
.icon-471-facebook-2--core--social-:before {
  content: "\ea88";
}
.icon-470-chevron-down-sm-solid--core--arrow-:before {
  content: "\ea87";
}
.icon-469-chevron-up-sm-solid--core--arrow-:before {
  content: "\ea86";
}
.icon-468-chevron-right-sm-solid--core--arrow-:before {
  content: "\ea85";
}
.icon-467-chevron-left-sm-solid--core--arrow-:before {
  content: "\ea84";
}
.icon-466-chevron-down-sm-outline--core--arrow-:before {
  content: "\ea83";
}
.icon-465-chevron-up-sm-outline--core--arrow-:before {
  content: "\ea82";
}
.icon-464-chevron-right-sm-outline--core--arrow-:before {
  content: "\ea81";
}
.icon-463-chevron-left-sm-outline--core--arrow-:before {
  content: "\ea80";
}
.icon-462-minus-solid--core--interface-:before {
  content: "\ea7f";
}
.icon-461-plus-solid--core--interface-:before {
  content: "\ea7e";
}
.icon-460-question-outline--core--interface--alert-:before {
  content: "\ea7d";
}
.icon-459-success-sm-solid--core--interface-:before {
  content: "\eaa6";
}
.icon-458-close-sm-solid--core--interface-:before {
  content: "\eaa5";
}
.icon-457-success-outline-sm--core--interface-:before {
  content: "\eaa4";
}
.icon-456-close-outline-sm--core--interface-:before {
  content: "\eaa3";
}
.icon-455-success-solid--core--interface-:before {
  content: "\eaa2";
}
.icon-454-close-solid--core--interface-:before {
  content: "\eaa1";
}
.icon-453-email-mark--communication-:before {
  content: "\eaa0";
}
.icon-452-email-receive--communication-:before {
  content: "\ea9f";
}
.icon-451-page-comment--interface--communication-:before {
  content: "\ea9e";
}
.icon-450-win-champion-pedestal--betting-:before {
  content: "\ea9d";
}
.icon-449-paint-spray-style--interface-:before {
  content: "\ea9c";
}
.icon-448-paint-bucket-style--interface-:before {
  content: "\ea9b";
}
.icon-447-toggle-on--interface-:before {
  content: "\ea9a";
}
.icon-446-toggle-off--interface-:before {
  content: "\ea99";
}
.icon-445-law-bank-institution--misc-:before {
  content: "\ea98";
}
.icon-444-scissors-2-cut--interface-:before {
  content: "\ea97";
}
.icon-443-scissors-1-cut--interface-:before {
  content: "\ea96";
}
.icon-442-access-door-key-lock--misc-:before {
  content: "\ea95";
}
.icon-441-settings-3-controls--core--interface--setting-:before {
  content: "\ea94";
}
.icon-440-smiley-sad--misc-:before {
  content: "\eabb";
}
.icon-439-smiley-confused--misc-:before {
  content: "\eaba";
}
.icon-438-smiley-not-sure--misc-:before {
  content: "\eab9";
}
.icon-437-smiley-neutral--misc-:before {
  content: "\eab8";
}
.icon-436-smiley-smile--core--misc-:before {
  content: "\eab7";
}
.icon-435-target-misc-:before {
  content: "\eab6";
}
.icon-434-sun-brightness--interface--setting-:before {
  content: "\eab5";
}
.icon-433-control-pause--core--media-:before {
  content: "\eab4";
}
.icon-432-control-backward--media-:before {
  content: "\eab3";
}
.icon-431-control-foreward--media-:before {
  content: "\eab2";
}
.icon-430-control-stop--core--media-:before {
  content: "\eab1";
}
.icon-429-control-play--core--media-:before {
  content: "\eab0";
}
.icon-428-heart-favorite-2--core--interface-:before {
  content: "\eaaf";
}
.icon-427-tables-2--interface-:before {
  content: "\eaae";
}
.icon-426-control-visibility--interface--setting-:before {
  content: "\eaad";
}
.icon-425-chart-3--arrow--chart-:before {
  content: "\eaac";
}
.icon-424-style-paint--interface-:before {
  content: "\eaab";
}
.icon-423-email-open-read--communication-:before {
  content: "\eaaa";
}
.icon-422-pin-2--interface-:before {
  content: "\eaa9";
}
.icon-421-push-message-comment--communication-:before {
  content: "\eaa8";
}
.icon-420-edit-message-comment--core--communication-:before {
  content: "\eaa7";
}
.icon-419-bullet-3--interface-:before {
  content: "\eacf";
}
.icon-418-bullet-2--interface-:before {
  content: "\eace";
}
.icon-417-bullet-1--interface-:before {
  content: "\eacd";
}
.icon-416-bullet-3-solid--interface-:before {
  content: "\eacc";
}
.icon-415-bullet-2-solid--interface-:before {
  content: "\eacb";
}
.icon-414-bullet-1-solid--interface-:before {
  content: "\eaca";
}
.icon-413-write-edit-article--core--interface--document-:before {
  content: "\eac9";
}
.icon-412-external-link--core--interface--arrow-:before {
  content: "\eac8";
}
.icon-411-document-export-external--arrow--document-:before {
  content: "\eac7";
}
.icon-410-insights-idea--misc-:before {
  content: "\eac6";
}
.icon-409-ab-testing--misc-:before {
  content: "\eac5";
}
.icon-408-message-arrow-interface-:before {
  content: "\eac4";
}
.icon-407-today-date-solid--interface--betting-:before {
  content: "\eac3";
}
.icon-406-trophy-tournament-cup-solid--betting-:before {
  content: "\eac2";
}
.icon-405-livezilla-godzilla--misc-:before {
  content: "\eac1";
}
.icon-404-user-risk-centre--smp-:before {
  content: "\eac0";
}
.icon-403-report-centre--smp-:before {
  content: "\eabf";
}
.icon-402-invoice-centre--smp-:before {
  content: "\eabe";
}
.icon-401-tutorial-centre--smp-:before {
  content: "\eabd";
}
.icon-400-tour-centre--smp-:before {
  content: "\eabc";
}
.icon-399-match-centre--smp-:before {
  content: "\eae0";
}
.icon-398-user-centre--smp-:before {
  content: "\eadf";
}
.icon-397-invoice-admin--smp-:before {
  content: "\eade";
}
.icon-396-scout-admin--smp-:before {
  content: "\eadd";
}
.icon-395-tour-admin--smp-:before {
  content: "\eadc";
}
.icon-394-match-admin--smp-:before {
  content: "\eadb";
}
.icon-393-vertical-layout--betting-:before {
  content: "\eada";
}
.icon-392-horizontal-layout--betting-:before {
  content: "\ead9";
}
.icon-391-overlay-layout--betting-:before {
  content: "\ead8";
}
.icon-390-match-overview-solid--betting-:before {
  content: "\ead7";
}
.icon-389-alarm-solid--core--interface--alert-:before {
  content: "\ead6";
}
.icon-388-search-solid--interface-:before {
  content: "\ead5";
}
.icon-387-star-solid--interface-:before {
  content: "\ead4";
}
.icon-386-star-2--core--interface-:before {
  content: "\ead3";
}
.icon-385-star-2-solid--core--interface-:before {
  content: "\ead2";
}
.icon-384-robot--user-:before {
  content: "\ead1";
}
.icon-383-text--interface-:before {
  content: "\ead0";
}
.icon-382-male-youth--user-:before {
  content: "\ea7c";
}
.icon-381-female-youth--user-:before {
  content: "\ea7b";
}
.icon-380-external-document--document-:before {
  content: "\ea7a";
}
.icon-379-external-website--interface--arrow-:before {
  content: "\ea79";
}
.icon-378-check-success-2--core--interface-:before {
  content: "\ea78";
}
.icon-377-close-2--core--interface-:before {
  content: "\ea77";
}
.icon-376-success-2-outline--core--interface-:before {
  content: "\ea76";
}
.icon-375-close-2-outline--core--interface-:before {
  content: "\ea75";
}
.icon-374-circle-outline--interface-:before {
  content: "\ea74";
}
.icon-373-circle-full--interface-:before {
  content: "\ea73";
}
.icon-372-chart-3--core--chart--arrow-:before {
  content: "\ea72";
}
.icon-371-match-overview--interface--betting-:before {
  content: "\ea71";
}
.icon-370-cart-add--interface-:before {
  content: "\ea70";
}
.icon-369-widgets--core--interface-:before {
  content: "\ea6f";
}
.icon-368-window-sja--interface-:before {
  content: "\ea6e";
}
.icon-367-modal-sja--interface-:before {
  content: "\ea6d";
}
.icon-366-inline-sja--interface-:before {
  content: "\ea6c";
}
.icon-365-tooltip-sja--interface-:before {
  content: "\ea6b";
}
.icon-364-volume-control--chart-:before {
  content: "\ea6a";
}
.icon-363-remove-zoom-out-2--core--interface-:before {
  content: "\ea69";
}
.icon-362-add-zoom-in-2--core--interface-:before {
  content: "\ea68";
}
.icon-361-stop--interface-:before {
  content: "\ea67";
}
.icon-360-overview--core--interface-:before {
  content: "\ea66";
}
.icon-359-venue-stadium--betting-:before {
  content: "\ea65";
}
.icon-358-teams--betting-:before {
  content: "\ea64";
}
.icon-357-tables--interface--betting-:before {
  content: "\ea63";
}
.icon-356-referee-whistle--betting-:before {
  content: "\ea62";
}
.icon-355-h2h--betting-:before {
  content: "\ea61";
}
.icon-354-fixtures-bracket--betting-:before {
  content: "\ea60";
}
.icon-353-odds--betting-:before {
  content: "\ea5f";
}
.icon-352-language-settings-2--interface--setting-:before {
  content: "\ea5e";
}
.icon-351-language-regional-settings--interface--setting-:before {
  content: "\ea5d";
}
.icon-350-yesterday-date--interface--betting-:before {
  content: "\ea5c";
}
.icon-349-today-date--interface--betting-:before {
  content: "\ea5b";
}
.icon-348-tomorrow-date--interface--arrow--betting-:before {
  content: "\ea5a";
}
.icon-347-prematch--interface--betting-:before {
  content: "\ea59";
}
.icon-346-document-copy--core--document-:before {
  content: "\ea58";
}
.icon-345-network-2--interface-:before {
  content: "\ea57";
}
.icon-344-sitemap-2--interface-:before {
  content: "\ea56";
}
.icon-343-login--core--interface--user-:before {
  content: "\ea55";
}
.icon-342-document3--core--document-:before {
  content: "\ea54";
}
.icon-341-chart-2--arrow--chart-:before {
  content: "\ea53";
}
.icon-340-youtube-outline--social-:before {
  content: "\ea52";
}
.icon-339-yen2--misc-:before {
  content: "\ea51";
}
.icon-338-pound2--misc-:before {
  content: "\ea50";
}
.icon-337-euro2--misc-:before {
  content: "\ea4f";
}
.icon-336-dollar2--misc-:before {
  content: "\ea4e";
}
.icon-335-percent2--interface-:before {
  content: "\ea4d";
}
.icon-334-key1--core--misc-:before {
  content: "\ea4c";
}
.icon-333-add-comment--core--interface-:before {
  content: "\ea4b";
}
.icon-332-comment-2--core--interface--communication-:before {
  content: "\ea4a";
}
.icon-331-back-1--interface-:before {
  content: "\ea49";
}
.icon-330-admin2--core--interface--user--setting-:before {
  content: "\ea48";
}
.icon-329-dashboard1--core--chart-:before {
  content: "\ea47";
}
.icon-328-graph-document--chart-:before {
  content: "\ea46";
}
.icon-327-graph--core--chart-:before {
  content: "\ea45";
}
.icon-326-table-descending--core--arrow-:before {
  content: "\ea44";
}
.icon-325-table-ascending--core--arrow-:before {
  content: "\ea43";
}
.icon-324-table-sort--core--arrow-:before {
  content: "\e9ec";
}
.icon-323-table-descending-outline--arrow-:before {
  content: "\e9eb";
}
.icon-322-table-ascending-outline--arrow-:before {
  content: "\e9ea";
}
.icon-321-table-sort-outline--arrow-:before {
  content: "\e9e9";
}
.icon-320-attention-solid--core--interface--alert-:before {
  content: "\e9dc";
}
.icon-319-question-solid--core--interface--alert-:before {
  content: "\e9dd";
}
.icon-318-success-solid--interface-:before {
  content: "\e9de";
}
.icon-317-information-solid--core--interface--alert-:before {
  content: "\e9df";
}
.icon-316-error-solid--core--interface--alert-:before {
  content: "\e9e0";
}
.icon-315-sport-pitch-field--betting--sport-:before {
  content: "\e9e1";
}
.icon-314-scoreboard-match--betting-:before {
  content: "\e9e2";
}
.icon-313-photo--core--interface--media-:before {
  content: "\e9e3";
}
.icon-312-network--interface-:before {
  content: "\e9e4";
}
.icon-311-paint-style--core--interface--media-:before {
  content: "\e9e5";
}
.icon-310-switch-off--interface-:before {
  content: "\e9e6";
}
.icon-309-question-outline--core--interface--alert-:before {
  content: "\e9e7";
}
.icon-308-picture-album--interface--media-:before {
  content: "\e9e8";
}
.icon-307-cloud-crossed--interface-:before {
  content: "\ea42";
}
.icon-306-cloud-cross--interface-:before {
  content: "\ea41";
}
.icon-305-cloud-remove--interface-:before {
  content: "\ea40";
}
.icon-304-cloud-add--interface-:before {
  content: "\ea3f";
}
.icon-303-cloud-check--interface-:before {
  content: "\ea3e";
}
.icon-302-cloud-database--interface-:before {
  content: "\ea3d";
}
.icon-301-cloud-settings--interface-:before {
  content: "\ea3c";
}
.icon-300-cloud-lock--interface-:before {
  content: "\ea3b";
}
.icon-299-cloud-sync--interface-:before {
  content: "\ea3a";
}
.icon-298-cloud-lightning--interface-:before {
  content: "\ea39";
}
.icon-297-clock-time-alarm-disabled--interface-:before {
  content: "\ea38";
}
.icon-296-clock-time-alarm-active--interface-:before {
  content: "\ea37";
}
.icon-295-clock-time-alarm-remove--interface-:before {
  content: "\ea36";
}
.icon-294-clock-time-alarm-add--interface-:before {
  content: "\ea35";
}
.icon-293-clock-time-alarm--interface-:before {
  content: "\ea34";
}
.icon-292-alarm-add-bell--core--interface--alert-:before {
  content: "\ea33";
}
.icon-291-microphone-mute--media-:before {
  content: "\ea32";
}
.icon-290-satellite-dish--communication-:before {
  content: "\ea31";
}
.icon-289-satellite--communication-:before {
  content: "\ea30";
}
.icon-288-telephone-call-in--communication-:before {
  content: "\ea2f";
}
.icon-287-telephone-call-out--communication-:before {
  content: "\ea2e";
}
.icon-286-telephone-signal--communication-:before {
  content: "\ea2d";
}
.icon-285-telephone-locked--communication-:before {
  content: "\ea2c";
}
.icon-284-telephone-call--communication-:before {
  content: "\ea2b";
}
.icon-283-first-aid-case--misc-:before {
  content: "\ea2a";
}
.icon-282-pulse--misc-:before {
  content: "\ea29";
}
.icon-281-medical-health--misc-:before {
  content: "\ea28";
}
.icon-280-mobile--core--interface--device-:before {
  content: "\ea27";
}
.icon-279-devices--core--interface--device-:before {
  content: "\ea26";
}
.icon-278-telephone--interface--communication-:before {
  content: "\ea25";
}
.icon-277-digits--misc-:before {
  content: "\ea24";
}
.icon-276-men-sign--core--user-:before {
  content: "\ea23";
}
.icon-275-women-sign--core--user-:before {
  content: "\ea22";
}
.icon-274-hearts-cards--misc-:before {
  content: "\ea21";
}
.icon-273-clubs-cards--misc-:before {
  content: "\ea20";
}
.icon-272-diamonds-cards--misc-:before {
  content: "\ea1f";
}
.icon-271-spades-cards--misc-:before {
  content: "\ea1e";
}
.icon-270-dice--misc-:before {
  content: "\ea1d";
}
.icon-269-note-3-music--media-:before {
  content: "\ea1c";
}
.icon-268-note-2-music--media-:before {
  content: "\ea1b";
}
.icon-267-note-1-music--media-:before {
  content: "\ea1a";
}
.icon-266-beach-handball--sport-:before {
  content: "\ea19";
}
.icon-265-rss-outline--core--social-:before {
  content: "\ea18";
}
.icon-264-share-outline--core--social-:before {
  content: "\ea17";
}
.icon-263-linkedin-outline--core--social-:before {
  content: "\ea16";
}
.icon-262-google-plus-outline--core--social-:before {
  content: "\ea15";
}
.icon-261-instagram-outline--core--social-:before {
  content: "\ea14";
}
.icon-260-windows-outline--core--social-:before {
  content: "\ea13";
}
.icon-259-android-outline--core--social-:before {
  content: "\ea12";
}
.icon-258-apple-outline--core--social-:before {
  content: "\ea11";
}
.icon-257-twitter-outline--core--social-:before {
  content: "\ea10";
}
.icon-256-paypal-outline--core--social-:before {
  content: "\ea0f";
}
.icon-255-facebook-outline--core--social-:before {
  content: "\ea0e";
}
.icon-254-admin-settings--core--interface--user--setting-:before {
  content: "\ea0d";
}
.icon-253-admin--core--interface--user--setting-:before {
  content: "\ea0c";
}
.icon-252-image-add--interface--media-:before {
  content: "\ea0b";
}
.icon-251-compressed-file--document-:before {
  content: "\ea0a";
}
.icon-250-patch--document-:before {
  content: "\ea09";
}
.icon-249-cube--core--interface-:before {
  content: "\ea08";
}
.icon-248-puzzle--core--misc-:before {
  content: "\ea07";
}
.icon-247-user-2-avatar--core--interface--user-:before {
  content: "\ea06";
}
.icon-246-flare--interface--setting-:before {
  content: "\ea05";
}
.icon-245-sun--interface--setting-:before {
  content: "\ea04";
}
.icon-244-filter--core--interface-:before {
  content: "\ea03";
}
.icon-243-newspaper--core--interface--document-:before {
  content: "\ea02";
}
.icon-242-sitemap--interface-:before {
  content: "\ea01";
}
.icon-241-cursor--interface-:before {
  content: "\ea00";
}
.icon-240-juice--misc-:before {
  content: "\e9ff";
}
.icon-239-settings-2--core--interface--setting-:before {
  content: "\e9fe";
}
.icon-238-pacman--misc-:before {
  content: "\e9fd";
}
.icon-237-wallet--interface--misc-:before {
  content: "\e9fc";
}
.icon-236-anchor--misc-:before {
  content: "\e9fb";
}
.icon-235-eyeglasses--misc-:before {
  content: "\e9fa";
}
.icon-234-lab-experiment--misc-:before {
  content: "\e9f9";
}
.icon-233-bulb--core--misc-:before {
  content: "\e9f8";
}
.icon-232-umbrella--misc-:before {
  content: "\e9f7";
}
.icon-231-wrench-tool--core--interface--setting-:before {
  content: "\e9f6";
}
.icon-230-coffee--misc-:before {
  content: "\e9f5";
}
.icon-229-male-mars--arrow--user--misc-:before {
  content: "\e9f4";
}
.icon-228-female-venus--user--misc-:before {
  content: "\e9f3";
}
.icon-227-history--core--interface--arrow--betting-:before {
  content: "\e9f2";
}
.icon-226-fire-hot--core--misc-:before {
  content: "\e9f1";
}
.icon-225-announcement--misc-:before {
  content: "\e9f0";
}
.icon-224-globe-3--misc-:before {
  content: "\e9ef";
}
.icon-223-globe-2--misc-:before {
  content: "\e9ee";
}
.icon-222-globe--misc-:before {
  content: "\e9ed";
}
.icon-221-apps--core--interface-:before {
  content: "\e9db";
}
.icon-220-hamburger-menu--core--interface-:before {
  content: "\e9da";
}
.icon-219-more-horizontal--core--interface-:before {
  content: "\e9d9";
}
.icon-218-chevron-down-sm--core--arrow-:before {
  content: "\e9d8";
}
.icon-217-chevron-up-sm--core--arrow-:before {
  content: "\e9d7";
}
.icon-216-chevron-right-sm--core--arrow-:before {
  content: "\e9d6";
}
.icon-215-chevron-left-sm--core--arrow-:before {
  content: "\e9d5";
}
.icon-214-other-sport--misc--sport-:before {
  content: "\e9d4";
}
.icon-213-volleyball--sport-:before {
  content: "\e9d3";
}
.icon-212-waterpolo--sport-:before {
  content: "\e9d2";
}
.icon-211-snooker--sport-:before {
  content: "\e9d1";
}
.icon-210-softball--sport-:before {
  content: "\e9d0";
}
.icon-209-rugby--sport-:before {
  content: "\e9cf";
}
.icon-208-pesapallo--sport-:before {
  content: "\e9ce";
}
.icon-207-table-tennis--sport-:before {
  content: "\e9cd";
}
.icon-206-golf--sport-:before {
  content: "\e9cc";
}
.icon-205-handball--sport-:before {
  content: "\e9cb";
}
.icon-204-futsal--sport-:before {
  content: "\e9ca";
}
.icon-203-floorball--sport-:before {
  content: "\e9c9";
}
.icon-202-field-hockey--sport-:before {
  content: "\e9c8";
}
.icon-201-darts--sport-:before {
  content: "\e9c7";
}
.icon-200-cycling--sport-:before {
  content: "\e9c6";
}
.icon-199-curling--sport-:before {
  content: "\e9c5";
}
.icon-198-cricket--sport-:before {
  content: "\e9c4";
}
.icon-197-bowling--sport-:before {
  content: "\e9c3";
}
.icon-196-billiard-pool--sport-:before {
  content: "\e9c2";
}
.icon-195-beach-volleyball--sport-:before {
  content: "\e9c1";
}
.icon-194-baseball--sport-:before {
  content: "\e9c0";
}
.icon-193-bandy--sport-:before {
  content: "\e9bf";
}
.icon-192-badminton--sport-:before {
  content: "\e9be";
}
.icon-191-aussie-rules--sport-:before {
  content: "\e9bd";
}
.icon-190-american-football--sport-:before {
  content: "\e9bc";
}
.icon-188-file-out--interface--arrow-:before {
  content: "\e9bb";
}
.icon-187-file-in--interface--arrow-:before {
  content: "\e9ba";
}
.icon-186-speedometer--misc-:before {
  content: "\e9b9";
}
.icon-185-trophy-tournament-cup--betting-:before {
  content: "\e9b8";
}
.icon-184-broadcast-antenna--core--communication-:before {
  content: "\e9b7";
}
.icon-183-broadcast-live--core--communication-:before {
  content: "\e9b6";
}
.icon-182-wifi-locked--communication-:before {
  content: "\e9b5";
}
.icon-181-wifi--core--communication-:before {
  content: "\e9b4";
}
.icon-180-code-embed--interface-:before {
  content: "\e9b3";
}
.icon-179-bug--misc-:before {
  content: "\e9b2";
}
.icon-178-share-3--social-:before {
  content: "\e9b1";
}
.icon-177-share-2--core--interface--social--arrow-:before {
  content: "\e9b0";
}
.icon-176-unlink--interface-:before {
  content: "\e9af";
}
.icon-175-link--interface-:before {
  content: "\e9ae";
}
.icon-174-zoom-out--interface-:before {
  content: "\e9ad";
}
.icon-173-zoom-in--interface-:before {
  content: "\e9ac";
}
.icon-172-thumbs-down-dislike--social-:before {
  content: "\e9ab";
}
.icon-171-thumbs-up-like--social-:before {
  content: "\e9aa";
}
.icon-170-flow-wave--arrow-:before {
  content: "\e9a9";
}
.icon-169-switch--arrow-:before {
  content: "\e9a8";
}
.icon-168-move--interface--media--arrow-:before {
  content: "\e9a7";
}
.icon-167-minimize-2--interface--media--arrow-:before {
  content: "\e9a6";
}
.icon-166-maximize-2--interface--media--arrow-:before {
  content: "\e9a5";
}
.icon-165-contract-minimize--interface--media--arrow-:before {
  content: "\e9a4";
}
.icon-164-expand-maximize--interface--media--arrow-:before {
  content: "\e9a3";
}
.icon-163-shuffle--arrow-:before {
  content: "\e9a2";
}
.icon-162-arrow-left-down--arrow-:before {
  content: "\e9a1";
}
.icon-161-arrow-right-down--arrow-:before {
  content: "\e9a0";
}
.icon-160-arrow-left-up--arrow-:before {
  content: "\e99f";
}
.icon-159-arrow-right-up--arrow-:before {
  content: "\e99e";
}
.icon-158-arrow-left--arrow-:before {
  content: "\e99d";
}
.icon-157-arrow-right--arrow-:before {
  content: "\e99c";
}
.icon-156-arrow-down--arrow-:before {
  content: "\e99b";
}
.icon-155-arrow-up--arrow-:before {
  content: "\e99a";
}
.icon-154-percent--interface-:before {
  content: "\e999";
}
.icon-153-minus-remove--core--interface-:before {
  content: "\e998";
}
.icon-152-plus-add--core--interface-:before {
  content: "\e997";
}
.icon-151-check-confirm--core--interface-:before {
  content: "\e996";
}
.icon-150-layers-crossed--interface-:before {
  content: "\e995";
}
.icon-149-layers-stack--interface-:before {
  content: "\e994";
}
.icon-148-pointer-left--interface-:before {
  content: "\e993";
}
.icon-147-pointer-down--interface-:before {
  content: "\e992";
}
.icon-146-pointer-right--interface-:before {
  content: "\e991";
}
.icon-145-pointer-up--interface-:before {
  content: "\e990";
}
.icon-144-menu-outline--core--interface--document-:before {
  content: "\e98f";
}
.icon-143-cross-outline--core--interface-:before {
  content: "\e98e";
}
.icon-142-check-outline--core--interface-:before {
  content: "\e98d";
}
.icon-141-chevron-right-outline--arrow-:before {
  content: "\e98c";
}
.icon-140-chevron-left-outline--arrow-:before {
  content: "\e98b";
}
.icon-139-chevron-down-outline--arrow-:before {
  content: "\e98a";
}
.icon-138-chevron-up-outline--arrow-:before {
  content: "\e989";
}
.icon-137-arrow-right-outline--arrow-:before {
  content: "\e988";
}
.icon-136-arrow-left-outline--arrow-:before {
  content: "\e987";
}
.icon-135-arrow-down-outline--arrow-:before {
  content: "\e986";
}
.icon-134-arrow-up-outline--arrow-:before {
  content: "\e985";
}
.icon-133-percent-outline--interface-:before {
  content: "\e984";
}
.icon-132-minus-outline--core--interface-:before {
  content: "\e983";
}
.icon-131-plus-outline--core--interface--arrow-:before {
  content: "\e982";
}
.icon-130-exit-right--interface--interface--arrow-:before {
  content: "\e981";
}
.icon-129-exit-left--interface-:before {
  content: "\e980";
}
.icon-128-exit-down--interface-:before {
  content: "\e97f";
}
.icon-127-exit-up--interface-:before {
  content: "\e97e";
}
.icon-126-enter-right--interface--arrow-:before {
  content: "\e97d";
}
.icon-125-enter-left--interface--arrow-:before {
  content: "\e97c";
}
.icon-124-enter-down--interface--arrow-:before {
  content: "\e97b";
}
.icon-123-enter-up--interface--arrow-:before {
  content: "\e97a";
}
.icon-122-upload-2--arrow-:before {
  content: "\e979";
}
.icon-121-download-2--core--arrow-:before {
  content: "\e978";
}
.icon-120-sync---core--interface--arrow-:before {
  content: "\e977";
}
.icon-119-redo-2--interface--arrow-:before {
  content: "\e976";
}
.icon-118-undo-2--interface--arrow-:before {
  content: "\e975";
}
.icon-117-leaf-nature--misc-:before {
  content: "\e974";
}
.icon-116-diamond--misc-:before {
  content: "\e973";
}
.icon-115-map-place--core--misc-:before {
  content: "\e972";
}
.icon-114-tablet--core--interface--device-:before {
  content: "\e971";
}
.icon-113-laptop--core--interface--device-:before {
  content: "\e970";
}
.icon-112-euro-1-coin--misc-:before {
  content: "\e96f";
}
.icon-111-dollar-1-coin--misc-:before {
  content: "\e96e";
}
.icon-110-bubble-comment--core--communication-:before {
  content: "\e96d";
}
.icon-109-bubble-question--communication-:before {
  content: "\e96c";
}
.icon-108-bubble-alert--communication-:before {
  content: "\e96b";
}
.icon-107-bubble-conversation--communication-:before {
  content: "\e96a";
}
.icon-106-bubble--core--communication-:before {
  content: "\e969";
}
.icon-105-chart-statistics--core--chart-:before {
  content: "\e968";
}
.icon-104-monitor-desktop--core--interface--device-:before {
  content: "\e967";
}
.icon-103-mouse-click--interface--device-:before {
  content: "\e966";
}
.icon-102-player-tshirt-clothes--misc-:before {
  content: "\e965";
}
.icon-101-tag-group--core--interface-:before {
  content: "\e964";
}
.icon-100-tag--core--interface-:before {
  content: "\e963";
}
.icon-099-document-4-article--core--document-:before {
  content: "\e962";
}
.icon-098-register-signature--core--document-:before {
  content: "\e961";
}
.icon-097-badge-prize--misc-:before {
  content: "\e960";
}
.icon-096-video-play--interface--media-:before {
  content: "\e95f";
}
.icon-095-video-off--interface--media-:before {
  content: "\e95e";
}
.icon-094-video-on--interface--media-:before {
  content: "\e95d";
}
.icon-093-folder-upload--arrow--document-:before {
  content: "\e95c";
}
.icon-092-folder-download--arrow--document-:before {
  content: "\e95b";
}
.icon-091-folder-remove--document-:before {
  content: "\e95a";
}
.icon-090-folder-add--document-:before {
  content: "\e959";
}
.icon-089-folder-search--document-:before {
  content: "\e958";
}
.icon-088-folder-directory--document-:before {
  content: "\e957";
}
.icon-087-document-3-files-stack--core--document-:before {
  content: "\e956";
}
.icon-086-document-checked--document-:before {
  content: "\e955";
}
.icon-085-document-add--document-:before {
  content: "\e954";
}
.icon-084-document-2--core--document-:before {
  content: "\e953";
}
.icon-083-save--core--interface-:before {
  content: "\e952";
}
.icon-082-eye-privacy-view--core--interface-:before {
  content: "\e951";
}
.icon-081-eye-public-view--core--interface-:before {
  content: "\e950";
}
.icon-080-paper-plane-send--interface--communication-:before {
  content: "\e94f";
}
.icon-079-trash-delete--core--interface-:before {
  content: "\e94e";
}
.icon-078-flag-report--communication-:before {
  content: "\e94d";
}
.icon-077-unlock--interface-:before {
  content: "\e94c";
}
.icon-076-lock-privacy--interface-:before {
  content: "\e94b";
}
.icon-075-shield-off--interface-:before {
  content: "\e94a";
}
.icon-074-shield-alert--interface-:before {
  content: "\e949";
}
.icon-073-shield-on--interface-:before {
  content: "\e948";
}
.icon-072-shield-security--interface-:before {
  content: "\e947";
}
.icon-071-database-download--arrow--interface-:before {
  content: "\e946";
}
.icon-070-database-upload--arrow--interface-:before {
  content: "\e945";
}
.icon-069-database-check--interface-:before {
  content: "\e944";
}
.icon-068-database-lock--interface-:before {
  content: "\e943";
}
.icon-067-database-remove--interface-:before {
  content: "\e942";
}
.icon-066-database-add--interface-:before {
  content: "\e941";
}
.icon-065-database-storage--interface-:before {
  content: "\e940";
}
.icon-064-sound-off--interface--media-:before {
  content: "\e93f";
}
.icon-063-sound-on--interface--media-:before {
  content: "\e93e";
}
.icon-062-sound-louder--interface--media-:before {
  content: "\e93d";
}
.icon-061-sound-loudest--interface--media-:before {
  content: "\e93c";
}
.icon-060-star-1--interface-:before {
  content: "\e93b";
}
.icon-059-attachment--core--interface-:before {
  content: "\e93a";
}
.icon-058-email--core--communication-:before {
  content: "\e939";
}
.icon-057-cloud-upload--arrow-:before {
  content: "\e938";
}
.icon-056-cloud-download--arrow-:before {
  content: "\e937";
}
.icon-055-list-1--core--interface-:before {
  content: "\e936";
}
.icon-054-microphone--media-:before {
  content: "\e935";
}
.icon-053-pie-chart--chart-:before {
  content: "\e934";
}
.icon-052-redo--interface--arrow-:before {
  content: "\e933";
}
.icon-051-undo--interface--arrow-:before {
  content: "\e932";
}
.icon-050-shopping-bag--interface-:before {
  content: "\e931";
}
.icon-049-cart-full--interface-:before {
  content: "\e930";
}
.icon-048-cart--core--interface-:before {
  content: "\e92f";
}
.icon-047-book-open--document-:before {
  content: "\e92e";
}
.icon-046-briefcase--interface-:before {
  content: "\e92d";
}
.icon-045-map--core--misc-:before {
  content: "\e92c";
}
.icon-044-image--core--interface--media-:before {
  content: "\e92b";
}
.icon-043-gift--core--misc-:before {
  content: "\e92a";
}
.icon-042-printer--core--interface--device-:before {
  content: "\e929";
}
.icon-041-comment-1--interface--communication-:before {
  content: "\e928";
}
.icon-040-credit-card--core--interface--misc-:before {
  content: "\e927";
}
.icon-039-smiley-happy--misc-:before {
  content: "\e926";
}
.icon-038-energy-bolt--core--misc-:before {
  content: "\e925";
}
.icon-037-ghost-game--misc-:before {
  content: "\e924";
}
.icon-036-teach-education--user--misc-:before {
  content: "\e923";
}
.icon-035-notebook--document-:before {
  content: "\e922";
}
.icon-034-airplane--core--misc-:before {
  content: "\e921";
}
.icon-033-user-unfollow--interface--user-:before {
  content: "\e920";
}
.icon-032-user-following--interface--user-:before {
  content: "\e91f";
}
.icon-031-user-follow--interface--user-:before {
  content: "\e91e";
}
.icon-030-people-group--core--interface--user-:before {
  content: "\e91d";
}
.icon-029-user-male--core--interface--user-:before {
  content: "\e91c";
}
.icon-028-user-female--user--core--interface--user-:before {
  content: "\e91b";
}
.icon-027-settings-gear--core--interface--setting-:before {
  content: "\e91a";
}
.icon-026-document-1--core--interface-:before {
  content: "\e919";
}
.icon-025-rocket--core--misc-:before {
  content: "\e918";
}
.icon-024-home-house-1--core--interface-:before {
  content: "\e917";
}
.icon-023-ice-hockey--winter-sport--sport-:before {
  content: "\e916";
}
.icon-022-basketball--sport-:before {
  content: "\e915";
}
.icon-021-soccer--sport-:before {
  content: "\e914";
}
.icon-020-tennis--sport-:before {
  content: "\e913";
}
.icon-019-pin-1--core--interface-:before {
  content: "\e912";
}
.icon-018-alarm-bell--core--interface--alert-:before {
  content: "\e911";
}
.icon-017-like-heart-1--interface-:before {
  content: "\e910";
}
.icon-016-more-vertical--core--interface-:before {
  content: "\e90f";
}
.icon-015-repost--arrow-:before {
  content: "\e90e";
}
.icon-014-warning-triangle--core--interface--alert-:before {
  content: "\e90d";
}
.icon-013-success-check-circle--core--interface--alert-:before {
  content: "\e90c";
}
.icon-012-information-circle--core--interface--alert-:before {
  content: "\e90b";
}
.icon-011-warning-circle--core--interface--alert-:before {
  content: "\e90a";
}
.icon-010-calendar--core--interface--betting-:before {
  content: "\e909";
}
.icon-009-time--core--interface--betting-:before {
  content: "\e908";
}
.icon-008-chevron-left--arrow-:before {
  content: "\e907";
}
.icon-007-chevron-right--arrow-:before {
  content: "\e906";
}
.icon-006-edit--core--interface-:before {
  content: "\e905";
}
.icon-005-search--core--interface-:before {
  content: "\e904";
}
.icon-004-dropdown--core--interface--arrow-:before {
  content: "\e903";
}
.icon-003-close-x--core--interface-:before {
  content: "\e902";
}
.icon-002-chevron-up--arrow--arrow-:before {
  content: "\e901";
}
.icon-001-chevron-downn--arrow-:before {
  content: "\e900";
}
