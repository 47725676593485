@import (less) 'imports.less';


.transition (@prop: all, @time: 1s, @ease: linear) {
    -webkit-transition: @prop @time @ease;
    -moz-transition: @prop @time @ease;
    -o-transition: @prop @time @ease;
    -ms-transition: @prop @time @ease;
    transition: @prop @time @ease;
}

.footer {
    background: @token-global--color-gray-1200;
    color: @token-global--text-color-white;

    p {
        margin: 0rem 0rem 2rem;
    }

    img {
        max-width: 162px;
        margin-bottom: @token-global--margin-xxl;
    }
    
    a {
        color: @token-global--text-color-link-default--surface-on-dark;
        padding-left: 5px;

        &:hover{
            color: @token-global--text-color-link-active--surface-on-light;
        }
    }
}

.display-none {display: none;}

.ui.message.info-message {
    margin: 1rem;
    position: fixed;
    right: 0;
}

.ui.basic.segment {
    margin-top: 0;
    padding: 0;
}

.button_add {
    cursor: pointer;
    i {
        color: white;
        background: @token-global--color-blue-br-600;
        font-size: 1.5rem;
        border-radius: 50%;
        padding: 10px;
    }
}

.button_action {
    display: flex;
    border: 1px solid @token-global--color-blue-br-200;
    background: @token-global--color-blue-br-50;
    border-radius: 50%;
    padding: 6px 7px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
        color: @token-global--color-blue-br-500;
        margin: 0;
        width: auto;
        height: auto;
        font-size: 1.5rem;
    }

    &:hover {
        background: @token-global--color-blue-br-200;

        i {
            color: @token-global--color-blue-br-700;
        }
    }
}

.sc__title{
    margin-bottom: 1.5rem;

    span:first-child {margin-right: 5px;}
    a {cursor:pointer;}
    span:not(:first-child) {color: @token-global--color-blue-700;}
}

.sc__legend__action {
    margin-left: 2rem;

    i {
        font-size: @token-global--font-size-900;
    }
    .sc__event-item__icons.colored-bg {
        margin-right: 0.5rem;
        padding: 5px;
    }
}

.datepicker {
    span {white-space: nowrap; display: flex; align-items: center;}
}
.datepicker__week {
    span {
        cursor: pointer;
        font-size: @token-global--font-size-800;
        position: relative;

        &:after {
            content:'';
            bottom: 0;
            border-radius: 10px;
            border-bottom: 2px solid @token-global--color-gray-900;
            width: 0%;
            position: absolute;
            transition: all 200ms ease-in;
        }
    }
    span:hover {
        color: @token-global--color-gray-900;

        &:after {
            width: 100%;
        }
    }
    span.active {
        &:after {
            width: 100%;
            border-bottom: 2px solid @token-global--color-gray-1400;
        }
    }
}

.react-datepicker-wrapper { width: auto;}
.react-datepicker__tab-loop {
    .react-datepicker-popper {z-index: 2;}
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 2;
}
.sc__fixed-header {
    background: @token-global--color-blue-br-600;
    color: white; 
    box-sizing: border-box;

    .sc__column {
        background: @token-global--color-blue-br-600;
        padding: 10px;
        box-sizing: border-box;

        &:last-child {
            background: @token-global--color-blue-br-700;
        }
    }
}

.sc__filter {
    padding: 2rem 0 1rem;
    border: 1px solid transparent;

    .ui.selection.dropdown {
        width: auto; margin-left: 10px;
    }
    .ui.fluid.dropdown {
        display: flex;

        &:first-child {
            margin-left: 0;
        }
    }

    >div {
        position: relative;
    }
}

.filters-scroll {
    position: absolute;
    right: 10px;
    top: 65px;
    padding: 0;

    >div {
        overflow: hidden;
        padding-right: 10px;
    }
    .filter-icon {
        color: @token-global--color-blue-br-300;
        background: @token-global--color-blue-br-50;
        border: 1px solid @token-global--color-blue-br-300;
        z-index: 2;

        &:hover {
            background: @token-global--color-blue-br-100;
        }
    }

    .transition-container {
        .transition(right, .3s, ease-in);
        padding: 10px 50px 10px 10px;
        box-sizing: border-box;
        border-radius: 3px;
        background: white;
        border: 1px solid @token-global--color-blue-br-100;
        position: absolute;
        z-index: 1;
        //right: -327px;
        opacity: 0;

        .ui.fluid.dropdown {
            .transition(opacity, .5s, ease-in);
            //display: none;
            opacity: 0;
        }
    }
}
.filters-scroll.filters-active {
    >div {
        overflow: initial;
    }

    .ui.selection.dropdown:first-child {
        margin-left: 0px;
    }

    >div {
        overflow: initial;
    }

    .filter-icon {
        color: white;
        background: @token-global--color-blue-br-300;

        &:hover {
            background: @token-global--color-blue-br-400;
        }
    }
    .transition-container {
        .transition(right, .2s, ease-in);
        right: 0px;
        opacity: 1;

        .ui.fluid.dropdown {
            //display: flex;
            opacity: 1;
        }
    }
}

.filter-icon {
    font-size: 25px;
    color: @token-global--icon-color-neutral--surface-on-light;
    border-radius: 50%;
    padding: 3px;
    box-sizing: border-box;
    margin-left: 10px;
    cursor: default;
}

table {
    a {cursor: pointer;}
}

.table-active {
    background: @token-global--color-blue-br-50 !important;
    color: @token-global--color-blue-br-900 !important;
    box-shadow: 0px 0px 0px @token-global--color-blue-br-200 inset;
}

.modal, .livecontrol-channel{
    h5 {
        margin-bottom: 0;
        margin-right: 10px;
    }
    .sc__event-item__icons.colored-bg{
        padding: 6px;
        margin-right: 0;
        font-size: 1rem;
    }

    .sr-icon.sr-icon-hover {
        color: @token-global--color-gray-400;
        cursor: pointer;

        &:hover {
            color: @token-global--color-gray-600;
        }
    }
    
    .sr-icon {
        font-size: 1.2rem;
        color: @token-global--color-gray-500;
    }

    .sticky-column {
        position: sticky;
        bottom: 50px;
        background: white;
        padding: 10px 0;

        >div {
            background: white;
            border: 1px solid @token-global--color-gray-200;
            border-radius: 0.28571429rem;
            box-shadow: 1px 1px 5px #dedede;
        }
    }

    .ui.pointing.menu {
        display: flex;

        a {
            flex: 1;
        }
    }

    .gray-input {
        input {
            color: @token-global--color-gray-1400;
        }
    }
}

.sticky-bottom-actions {
    position: sticky;
    bottom: -1em;
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 10;
}

tr.highlight {
    position: relative;
    background: var(--highlight-row-bg);
}

:root {
    --highlight-row-bg: @token-global--color-orange-50;
}

.highlighted_action {
    position: absolute;
    top: -10px;
    left: -15px;
    z-index: 1;
}

.highlighted_stared {
    --highlight-row-bg: @token-global--color-yellow-sd-50;

    .sc__event-item__icons:not(.sc__event-item__icons) {
        border: 1px solid #f8ebae;
    }
}

.volume_track {
    margin-right: 10px;

    .ui.input { max-width: 100%; }
    
    input {
        display: flex;
        flex: 1; 
    }
}

.ui.form.volume_form {
    max-width: 40px;

    input {
        font-size: 0.875rem;
        padding: 0.67857143em 0;
        text-align: center;
        
        &::placeholder {
            color: @token-global--color-gray-600;
        }
    }
}

.live-event {
    display: flex;
    align-items: center;

    i { font-size: 7px;}
}

//just for präsi
.ui.popup {
    padding: 1rem 0.8rem!important;

    .sc__event-item__icons.colored-bg {
        padding: 5px;
    }
}

.ui.grid.modal_grid { margin: 0;}
.listbox-w-input {
    input {width: 70%;}
    .ui.dropdown.label {width: 30%;}
}


//LIVE CONTROL -- START --
//testi testi test 

.livecontrol {
    .sc__title {
        margin-bottom: 0;
    }

    .row {
        box-sizing: border-box;
        //border-bottom: 1px solid #dedede;
        position: relative;
        min-height: 100px;
    }
    .row.active {
        background: @token-global--color-red-sr-50;
    }
    .sc-timeline-marker {
        position: absolute;
        border-left: 1px solid #91c7ef;

        &::before {
            top: 0;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            border-top: 7px solid #91c7ef;
        }

        .sc-tooltip {
            position: absolute;
            top: 44px;
            bottom: initial;
            align-items: center;

            &::before {
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-right: 5px solid #2489c5;
                top: initial;
                right: 100%;
            }
        }

    }
    .sc__column__events {
        margin-top: 20px;
    }

    .sc__column__timetable {
        background: @token-global--color-blue-br-50;
    }

    .livecontrol__timetable__bg {
        background: white;
    }
    
    .livecontrol__timetable__header-bg {
        .sc__column__time {
            &::before {
                border-right: 1px solid @token-global--color-blue-br-300;
            }
    
            &::after {
                border-right: 1px dashed @token-global--color-blue-br-400;
            }
        }
    }
    
    .sc__column__time {
        padding: 0.5rem 1.5rem;

        &:first-child {
            &::before {
                border-right: 0;
                background: transparent;
            }
        }

        &::before {
            left: 0;
            border-right: 1px solid #dedede;
        }

        &::after {
            border-right: 1px dashed #dedede;
            left: 50%;
        }

        &::before, &::after {
            height: 100%;
            width: 1px;
            background-color: #fff;
            top: 0;
            bottom: 0;
        }

    }
    .sc__column__time__bg {
        background: transparent;
        color: @token-global--color-blue-br-600;
    }

    .sc__event-item {
        position: absolute;
        height: auto;
        top: 0;
        bottom: 0;
        margin: 6px 0;
    }

    .sc__event-item {
        .sc__event-item__buttons  {
            display: flex;
        }
    }
}

.livecontrol__timetable__bg {
    .sc__column__time {
        background: white;
    }
}

.ui.button.lc__header__buttons {
    margin-right: 0.5rem;

    &:last-child {
        margin-right: 0;
    }
    
    span {
        margin-left: 0.5rem;
    }
}
.sc__lc__static.wrapper-grid {
    padding-right: 1rem;
    padding-top: 33px;
    padding-bottom: 33px;
}

.sc__lc__static {
    min-width: 300px;
    background: #F5F5F5;
    border-right: 1px solid #dedede;

    h3, h4 {
        margin: 0;
    }

    .header {
        font-weight: bold;
        font-size: 1.28571429em;
        margin-top: -0.21425em;
        line-height: 1.28571429em;
    }

    .ui.card, .ui.cards {
        margin: 0;
    }

    i {
        margin: 0;
    }

    button {
        white-space: nowrap;
    }

    span {
        margin-bottom: 1rem;
    }
}

.ui.button.lc__button {margin-bottom: 0.5rem;}

.livecontrol__body {
    margin-top: 1.5rem;
    min-height: 100vh;
}

.livecontrol__footer.wrapper-grid {
    padding-top: 1.5rem;
    padding-bottom: 130px;
}

.livecontrol__footer{
    border-top: 1px solid #dedede;

}

.lc_more-button {
    .ui.dropdown .menu {
        right: 0;
        left: inherit;
    }
}

.lc__dropdown { margin-right: 0.25em; }

.sc__event-item {
    .sc__event-item__buttons {
        display: none;
        padding: 0;
        margin-left: 3px;
        font-size: 1.5rem;
        border-left: 1px solid @token-global--color-blue-br-200;

        i {
            color: @token-global--color-blue-br-500;
            padding: 10px;
        }

        button {
            cursor: pointer;
            border: 0;
            padding: 0; 

            &:first-child {
                background: @token-global--color-blue-br-50;

                &:hover {
                    background: @token-global--color-blue-br-200;

                    i {
                        color: @token-global--color-blue-br-700;
                    }
                }
            }
            &:last-child {
                background: @token-global--color-blue-br-400;

                i {
                    color: @token-global--color-blue-br-100;
                }
                
                &:hover {
                    background: @token-global--color-blue-br-500;

                    i {
                        color: @token-global--color-blue-br-50;
                    }
                }
            }
        }
    }
}

.livecontrol-channel {
    .sc__event-item__icons {font-size: 1.5rem;}

    table a {
        font-size: 16px;
    }
}




//LIVE CONTROL -- END --



.display-none {display: none!important;}
.wrapper-grid {padding: 0 @token-global--padding-2-xl;}
.absolute { position: absolute; }
.relative {position: relative;}
.flex { display: flex; }
.flex-row {flex-direction: row;}
.flex-row-reverse {flex-direction: row-reverse;}
.flex-col {flex-direction: column;}
.flex-wrap {flex-wrap: wrap;}
.flex-nowrap {flex-wrap: nowrap;}
.box-border {box-sizing: border-box;}
.w-full {width: 100%;}
.h-full {height: 100%;}
.max-w-full {max-width: 100%;}
.flex-1 {flex: 1;}
.items-center {align-items: center;}
.items-end {align-items: flex-end;}
.justify-center {justify-content: center;}
.justify-end {justify-content: flex-end;}
.justify-between {justify-content: space-between;}
.z-01 {z-index: 1;}
.z-02 {z-index: 2;}
.font-bold {font-weight: 700;}
.uppercase {text-transform: uppercase;}
.line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.cursor-pointer {cursor: pointer;}
.text-lg {font-size: 1.125rem!important; line-height: 1.75rem;}
.text-sm {font-size: 0.875rem;}
.m-0 {margin: 0;}

.width-30-per {width: 30%;}
.width-70-per {width: 70%;}



.transition (@prop: all, @time: 1s, @ease: linear) {
    -webkit-transition: @prop @time @ease;
    -moz-transition: @prop @time @ease;
    -o-transition: @prop @time @ease;
    -ms-transition: @prop @time @ease;
    transition: @prop @time @ease;
}

@media screen and (max-width: 1500px) {
    .ui.grid.modal_grid > .row > [class*="eight wide"].column {
            width: 100%!important;
            order: 1;
            max-height: 550px;
            overflow-y: scroll;
            margin-bottom: 1.5rem;
        }
    .ui.grid.modal_grid > .row > [class*="four wide"].column {
        width: 50%!important;
        order: 3;

        &:first-child {
            order: 2;
        }
        
    }

    .modal .sticky-column {
        bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}



