@import (less) 'imports.less';

.mx-8 {margin: 0 2rem;}
.my-8 {margin: 2rem 0;}
.mr-8 {margin: 0 2rem 0 0;}
.ml-8 {margin: 0 0 0 2rem;}
.mr-2 {margin-right: 0.5rem;}
.ml-2 {margin-left: 0.5rem;}
.mb-8 {margin: 0 0 2rem 0;}
.mb-4 {margin: 0 0 1rem 0;}
.mt-4 {margin: 1rem 0 0 0;}
.mb-0 {margin-bottom: 0;}
.mb-2 {margin-bottom: 0.5rem;}
.py-8 {padding: 2rem 0;}
.p-4 {padding: 1rem;}
.pr-4 {padding: 0 1rem 0 0;}
.pl-4 {padding: 0 0 0 1rem;}

