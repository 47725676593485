/* Import LESS tokens */
/* Temporary LESS token to integrate it with SR design tokens */
.footer {
  background: #303030;
  color: #ffffff;
}
.footer p {
  margin: 0rem 0rem 2rem;
}
.footer img {
  max-width: 162px;
  margin-bottom: 1.5rem;
}
.footer a {
  color: #6cb3e4;
  padding-left: 5px;
}
.footer a:hover {
  color: #2b5abc;
}
.display-none {
  display: none;
}
.ui.message.info-message {
  margin: 1rem;
  position: fixed;
  right: 0;
}
.ui.basic.segment {
  margin-top: 0;
  padding: 0;
}
.button_add {
  cursor: pointer;
}
.button_add i {
  color: white;
  background: #2489c5;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 10px;
}
.button_action {
  display: flex;
  border: 1px solid #b5daf7;
  background: #ecf6fe;
  border-radius: 50%;
  padding: 6px 7px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.button_action i {
  color: #489ed6;
  margin: 0;
  width: auto;
  height: auto;
  font-size: 1.5rem;
}
.button_action:hover {
  background: #b5daf7;
}
.button_action:hover i {
  color: #0072b1;
}
.sc__title {
  margin-bottom: 1.5rem;
}
.sc__title span:first-child {
  margin-right: 5px;
}
.sc__title a {
  cursor: pointer;
}
.sc__title span:not(:first-child) {
  color: #2b5abc;
}
.sc__legend__action {
  margin-left: 2rem;
}
.sc__legend__action i {
  font-size: 1.25rem;
}
.sc__legend__action .sc__event-item__icons.colored-bg {
  margin-right: 0.5rem;
  padding: 5px;
}
.datepicker span {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.datepicker__week span {
  cursor: pointer;
  font-size: 1.125rem;
  position: relative;
}
.datepicker__week span:after {
  content: '';
  bottom: 0;
  border-radius: 10px;
  border-bottom: 2px solid #5f5f5f;
  width: 0%;
  position: absolute;
  transition: all 200ms ease-in;
}
.datepicker__week span:hover {
  color: #5f5f5f;
}
.datepicker__week span:hover:after {
  width: 100%;
}
.datepicker__week span.active:after {
  width: 100%;
  border-bottom: 2px solid #121212;
}
.react-datepicker-wrapper {
  width: auto;
}
.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 2;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2;
}
.sc__fixed-header {
  background: #2489c5;
  color: white;
  box-sizing: border-box;
}
.sc__fixed-header .sc__column {
  background: #2489c5;
  padding: 10px;
  box-sizing: border-box;
}
.sc__fixed-header .sc__column:last-child {
  background: #0072b1;
}
.sc__filter {
  padding: 2rem 0 1rem;
  border: 1px solid transparent;
}
.sc__filter .ui.selection.dropdown {
  width: auto;
  margin-left: 10px;
}
.sc__filter .ui.fluid.dropdown {
  display: flex;
}
.sc__filter .ui.fluid.dropdown:first-child {
  margin-left: 0;
}
.sc__filter > div {
  position: relative;
}
.filters-scroll {
  position: absolute;
  right: 10px;
  top: 65px;
  padding: 0;
}
.filters-scroll > div {
  overflow: hidden;
  padding-right: 10px;
}
.filters-scroll .filter-icon {
  color: #91c7ef;
  background: #ecf6fe;
  border: 1px solid #91c7ef;
  z-index: 2;
}
.filters-scroll .filter-icon:hover {
  background: #d9edfd;
}
.filters-scroll .transition-container {
  -webkit-transition: right 0.3s ease-in;
  -moz-transition: right 0.3s ease-in;
  -o-transition: right 0.3s ease-in;
  -ms-transition: right 0.3s ease-in;
  transition: right 0.3s ease-in;
  padding: 10px 50px 10px 10px;
  box-sizing: border-box;
  border-radius: 3px;
  background: white;
  border: 1px solid #d9edfd;
  position: absolute;
  z-index: 1;
  opacity: 0;
}
.filters-scroll .transition-container .ui.fluid.dropdown {
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
  opacity: 0;
}
.filters-scroll.filters-active > div {
  overflow: initial;
}
.filters-scroll.filters-active .ui.selection.dropdown:first-child {
  margin-left: 0px;
}
.filters-scroll.filters-active > div {
  overflow: initial;
}
.filters-scroll.filters-active .filter-icon {
  color: white;
  background: #91c7ef;
}
.filters-scroll.filters-active .filter-icon:hover {
  background: #6cb3e4;
}
.filters-scroll.filters-active .transition-container {
  -webkit-transition: right 0.2s ease-in;
  -moz-transition: right 0.2s ease-in;
  -o-transition: right 0.2s ease-in;
  -ms-transition: right 0.2s ease-in;
  transition: right 0.2s ease-in;
  right: 0px;
  opacity: 1;
}
.filters-scroll.filters-active .transition-container .ui.fluid.dropdown {
  opacity: 1;
}
.filter-icon {
  font-size: 25px;
  color: #404040;
  border-radius: 50%;
  padding: 3px;
  box-sizing: border-box;
  margin-left: 10px;
  cursor: default;
}
table a {
  cursor: pointer;
}
.table-active {
  background: #ecf6fe !important;
  color: #006298 !important;
  box-shadow: 0px 0px 0px #b5daf7 inset;
}
.modal h5,
.livecontrol-channel h5 {
  margin-bottom: 0;
  margin-right: 10px;
}
.modal .sc__event-item__icons.colored-bg,
.livecontrol-channel .sc__event-item__icons.colored-bg {
  padding: 6px;
  margin-right: 0;
  font-size: 1rem;
}
.modal .sr-icon.sr-icon-hover,
.livecontrol-channel .sr-icon.sr-icon-hover {
  color: #bfbfbf;
  cursor: pointer;
}
.modal .sr-icon.sr-icon-hover:hover,
.livecontrol-channel .sr-icon.sr-icon-hover:hover {
  color: #8c8c8c;
}
.modal .sr-icon,
.livecontrol-channel .sr-icon {
  font-size: 1.2rem;
  color: #a6a6a6;
}
.modal .sticky-column,
.livecontrol-channel .sticky-column {
  position: sticky;
  bottom: 50px;
  background: white;
  padding: 10px 0;
}
.modal .sticky-column > div,
.livecontrol-channel .sticky-column > div {
  background: white;
  border: 1px solid #dedede;
  border-radius: 0.28571429rem;
  box-shadow: 1px 1px 5px #dedede;
}
.modal .ui.pointing.menu,
.livecontrol-channel .ui.pointing.menu {
  display: flex;
}
.modal .ui.pointing.menu a,
.livecontrol-channel .ui.pointing.menu a {
  flex: 1;
}
.modal .gray-input input,
.livecontrol-channel .gray-input input {
  color: #121212;
}
.sticky-bottom-actions {
  position: sticky;
  bottom: -1em;
}
.sticky-bottom {
  position: sticky;
  bottom: 0;
}
.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 10;
}
tr.highlight {
  position: relative;
  background: var(--highlight-row-bg);
}
:root {
  --highlight-row-bg: #fff5ec;
}
.highlighted_action {
  position: absolute;
  top: -10px;
  left: -15px;
  z-index: 1;
}
.highlighted_stared {
  --highlight-row-bg: #ffffec;
}
.highlighted_stared .sc__event-item__icons:not(.sc__event-item__icons) {
  border: 1px solid #f8ebae;
}
.volume_track {
  margin-right: 10px;
}
.volume_track .ui.input {
  max-width: 100%;
}
.volume_track input {
  display: flex;
  flex: 1;
}
.ui.form.volume_form {
  max-width: 40px;
}
.ui.form.volume_form input {
  font-size: 0.875rem;
  padding: 0.67857143em 0;
  text-align: center;
}
.ui.form.volume_form input::placeholder {
  color: #8c8c8c;
}
.live-event {
  display: flex;
  align-items: center;
}
.live-event i {
  font-size: 7px;
}
.ui.popup {
  padding: 1rem 0.8rem !important;
}
.ui.popup .sc__event-item__icons.colored-bg {
  padding: 5px;
}
.ui.grid.modal_grid {
  margin: 0;
}
.listbox-w-input input {
  width: 70%;
}
.listbox-w-input .ui.dropdown.label {
  width: 30%;
}
.livecontrol .sc__title {
  margin-bottom: 0;
}
.livecontrol .row {
  box-sizing: border-box;
  position: relative;
  min-height: 100px;
}
.livecontrol .row.active {
  background: #ffecec;
}
.livecontrol .sc-timeline-marker {
  position: absolute;
  border-left: 1px solid #91c7ef;
}
.livecontrol .sc-timeline-marker::before {
  top: 0;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-top: 7px solid #91c7ef;
}
.livecontrol .sc-timeline-marker .sc-tooltip {
  position: absolute;
  top: 44px;
  bottom: initial;
  align-items: center;
}
.livecontrol .sc-timeline-marker .sc-tooltip::before {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #2489c5;
  top: initial;
  right: 100%;
}
.livecontrol .sc__column__events {
  margin-top: 20px;
}
.livecontrol .sc__column__timetable {
  background: #ecf6fe;
}
.livecontrol .livecontrol__timetable__bg {
  background: white;
}
.livecontrol .livecontrol__timetable__header-bg .sc__column__time::before {
  border-right: 1px solid #91c7ef;
}
.livecontrol .livecontrol__timetable__header-bg .sc__column__time::after {
  border-right: 1px dashed #6cb3e4;
}
.livecontrol .sc__column__time {
  padding: 0.5rem 1.5rem;
}
.livecontrol .sc__column__time:first-child::before {
  border-right: 0;
  background: transparent;
}
.livecontrol .sc__column__time::before {
  left: 0;
  border-right: 1px solid #dedede;
}
.livecontrol .sc__column__time::after {
  border-right: 1px dashed #dedede;
  left: 50%;
}
.livecontrol .sc__column__time::before,
.livecontrol .sc__column__time::after {
  height: 100%;
  width: 1px;
  background-color: #fff;
  top: 0;
  bottom: 0;
}
.livecontrol .sc__column__time__bg {
  background: transparent;
  color: #2489c5;
}
.livecontrol .sc__event-item {
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  margin: 6px 0;
}
.livecontrol .sc__event-item .sc__event-item__buttons {
  display: flex;
}
.livecontrol__timetable__bg .sc__column__time {
  background: white;
}
.ui.button.lc__header__buttons {
  margin-right: 0.5rem;
}
.ui.button.lc__header__buttons:last-child {
  margin-right: 0;
}
.ui.button.lc__header__buttons span {
  margin-left: 0.5rem;
}
.sc__lc__static.wrapper-grid {
  padding-right: 1rem;
  padding-top: 33px;
  padding-bottom: 33px;
}
.sc__lc__static {
  min-width: 300px;
  background: #F5F5F5;
  border-right: 1px solid #dedede;
}
.sc__lc__static h3,
.sc__lc__static h4 {
  margin: 0;
}
.sc__lc__static .header {
  font-weight: bold;
  font-size: 1.28571429em;
  margin-top: -0.21425em;
  line-height: 1.28571429em;
}
.sc__lc__static .ui.card,
.sc__lc__static .ui.cards {
  margin: 0;
}
.sc__lc__static i {
  margin: 0;
}
.sc__lc__static button {
  white-space: nowrap;
}
.sc__lc__static span {
  margin-bottom: 1rem;
}
.ui.button.lc__button {
  margin-bottom: 0.5rem;
}
.livecontrol__body {
  margin-top: 1.5rem;
  min-height: 100vh;
}
.livecontrol__footer.wrapper-grid {
  padding-top: 1.5rem;
  padding-bottom: 130px;
}
.livecontrol__footer {
  border-top: 1px solid #dedede;
}
.lc_more-button .ui.dropdown .menu {
  right: 0;
  left: inherit;
}
.lc__dropdown {
  margin-right: 0.25em;
}
.sc__event-item .sc__event-item__buttons {
  display: none;
  padding: 0;
  margin-left: 3px;
  font-size: 1.5rem;
  border-left: 1px solid #b5daf7;
}
.sc__event-item .sc__event-item__buttons i {
  color: #489ed6;
  padding: 10px;
}
.sc__event-item .sc__event-item__buttons button {
  cursor: pointer;
  border: 0;
  padding: 0;
}
.sc__event-item .sc__event-item__buttons button:first-child {
  background: #ecf6fe;
}
.sc__event-item .sc__event-item__buttons button:first-child:hover {
  background: #b5daf7;
}
.sc__event-item .sc__event-item__buttons button:first-child:hover i {
  color: #0072b1;
}
.sc__event-item .sc__event-item__buttons button:last-child {
  background: #6cb3e4;
}
.sc__event-item .sc__event-item__buttons button:last-child i {
  color: #d9edfd;
}
.sc__event-item .sc__event-item__buttons button:last-child:hover {
  background: #489ed6;
}
.sc__event-item .sc__event-item__buttons button:last-child:hover i {
  color: #ecf6fe;
}
.livecontrol-channel .sc__event-item__icons {
  font-size: 1.5rem;
}
.livecontrol-channel table a {
  font-size: 16px;
}
.display-none {
  display: none!important;
}
.wrapper-grid {
  padding: 0 2.25rem;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.box-border {
  box-sizing: border-box;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.max-w-full {
  max-width: 100%;
}
.flex-1 {
  flex: 1;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.z-01 {
  z-index: 1;
}
.z-02 {
  z-index: 2;
}
.font-bold {
  font-weight: 700;
}
.uppercase {
  text-transform: uppercase;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.m-0 {
  margin: 0;
}
.width-30-per {
  width: 30%;
}
.width-70-per {
  width: 70%;
}
@media screen and (max-width: 1500px) {
  .ui.grid.modal_grid > .row > [class*="eight wide"].column {
    width: 100%!important;
    order: 1;
    max-height: 550px;
    overflow-y: scroll;
    margin-bottom: 1.5rem;
  }
  .ui.grid.modal_grid > .row > [class*="four wide"].column {
    width: 50%!important;
    order: 3;
  }
  .ui.grid.modal_grid > .row > [class*="four wide"].column:first-child {
    order: 2;
  }
  .modal .sticky-column {
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
