/* Import LESS tokens */
/* Temporary LESS token to integrate it with SR design tokens */
.sr-gray-1400 {
  color: #121212;
}
.sr-gray-1000 {
  color: #4f4f4f;
}
.sr-gray-900 {
  color: #5f5f5f;
}
.sr-gray-800 {
  color: #737373;
}
.sr-gray-700 {
  color: #7f7f7f;
}
.sr-gray-600 {
  color: #8c8c8c;
}
.sr-gray-400 {
  color: #bfbfbf;
}
.sr-gray-100 {
  color: #f2f2f2;
}
.sr-red-700 {
  color: #e01616;
}
.sr-blue-br-500 {
  color: #489ed6;
}
.sr-blue-br-600 {
  color: #2489c5;
}
.sr-blue-br-700 {
  color: #0072b1;
}
