@import (less) 'imports.less';

.sr-gray-1400 {color: @token-global--color-gray-1400;}
.sr-gray-1000 {color: @token-global--color-gray-1000;}
.sr-gray-900 {color: @token-global--color-gray-900;}
.sr-gray-800 { color: @token-global--color-gray-800;}
.sr-gray-700 { color: @token-global--color-gray-700;}
.sr-gray-600 {color: @token-global--color-gray-600;}
.sr-gray-400 {color: @token-global--color-gray-400;}
.sr-gray-100 {color: @token-global--color-gray-100;}
.sr-red-700 {color: @token-global--color-red-700;}
.sr-blue-br-500 {color: @token-global--color-blue-br-500;}
.sr-blue-br-600 {color: @token-global--color-blue-br-600;}
.sr-blue-br-700 {color: @token-global--color-blue-br-700;}




