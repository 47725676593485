/* Import LESS tokens */
/* Temporary LESS token to integrate it with SR design tokens */
.sc-timeline-marker {
  position: relative;
  border-bottom: 1px solid #91c7ef;
}
.sc-timeline-marker::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #91c7ef;
}
.sc-timeline-marker .sc-tooltip {
  position: absolute;
  background: #2489c5;
  left: 10px;
  color: #ffffff;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 0.25rem;
  bottom: 100%;
  margin-bottom: 5px;
  left: 5px;
}
.sc-timeline-marker .sc-tooltip::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 100%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2489c5;
}
.schedule {
  min-height: 100vh;
}
.sc__column {
  border-right: 1px solid #cccccc;
}
.sc__column:first-child {
  width: 40%;
}
.sc__column:last-child {
  width: 60%;
  background: rgba(145, 199, 239, 0.05);
}
.sc__column__title {
  padding-left: 50px;
}
.sc__column__title .sc__column {
  border: 0;
}
.sc__scrollable-content {
  overflow-x: scroll;
}
.sc__column__events {
  min-width: 500px;
}
.col {
  cursor: pointer;
}
.col,
.row {
  padding: 0 2px;
}
.col h4,
.row h4 {
  margin: 0;
}
.sc__event-item__status {
  display: none;
}
.sc__event-item.live {
  border-color: #e01616;
  background: #fff4f4 !important;
}
.sc__event-item.live .sc__event-item__status {
  display: flex;
}
.sc__event-item.live .sc__event-item__time {
  color: #e01616;
}
.sc__event-item.scheduled {
  background: #F5F5F5;
}
.sc__event-item.available {
  background: #ecf6fe;
  border-color: #91c7ef;
}
.sc__event-item.tiny-item-height h4 {
  white-space: nowrap;
  display: inline;
  margin-right: 5px;
}
.sc__event-item.small-item-height div {
  flex-direction: row-reverse;
  align-items: center;
}
.sc__event-item.small-item-height h4 {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}
.sc__event-item.small-item-height .sc__event-item__top-row {
  margin-right: 3px;
}
.sc__event-item.small-item-height .sc__event-item__time,
.sc__event-item.small-item-height .sc__event-item__status,
.sc__event-item.small-item-height .sc__event-item__bottom-row {
  display: none;
}
.sc__event-item {
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  background: white;
}
.sc__event-item > div {
  padding: 0.2rem 0.4rem;
}
.sc__event-item i {
  color: #000000;
  display: flex;
  align-items: center;
}
.sc__event-item h4 {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.sc__event-item svg {
  height: 15px;
  width: 15px;
  background: #b6b6b6;
}
.sc__scrollable-area {
  position: relative;
}
.sc__all-columns {
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 50px;
}
.sc__event-item__time,
.sc__event-item__meta,
.sc__event-item__status {
  font-size: 0.875rem;
}
.sc__event-item__meta {
  padding-right: 10px;
}
.sc__event-item__meta i {
  padding-right: 2px;
  margin-top: 1px;
}
.sc__event-item__meta span:not(:last-child) {
  overflow: hidden;
  text-overflow: ellipsis;
}
.sc__event-item__meta span {
  line-height: 15px;
}
.sc__event-item__meta span:after {
  padding: 0 0.25rem;
}
.sc__event-item__meta span:last-child:after {
  display: none;
}
.sc__event-item__status {
  padding: 0 0.5rem;
}
.sc__event-item__icons,
.buttons__switch {
  font-size: 1.25rem;
}
.sc__event-item__icons.colored-bg {
  padding: 3px;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: -2px;
  background: var(--sc-action-bgcolor);
}
.sc__event-item__icons.colored-bg i {
  color: var(--sc-action-color);
}
:root {
  --sc-action-color: #fa8d24;
  --sc-action-bgcolor: #ffecd9;
}
.sc-event-stream-break {
  --sc-action-color: #808006;
  --sc-action-bgcolor: #d8d82b;
}
.sc-event-data-only {
  --sc-action-color: #0072b1;
  --sc-action-bgcolor: #d9edfd;
}
.sc-event-no-betting {
  --sc-action-color: #484878;
  --sc-action-bgcolor: #d9d9e5;
  position: relative;
}
.sc-event-no-betting:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 1px;
  background: var(--sc-action-color);
  width: 100%;
  top: 50%;
  transform: rotate(-45deg);
}
.highlighted_stared {
  --sc-action-color: #cea900;
  --sc-action-bgcolor: #ffffb6;
}
.sc__column__time {
  font-size: 0.938rem;
  position: relative;
}
.sc__column__time:before,
.sc__column__time:after {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  content: '';
  border: none;
  color: #fff;
  background-color: #fff;
}
.sc__column__time:before {
  bottom: 0;
  border-top: 1px solid #dedede;
}
.sc__column__time:after {
  top: 50%;
  border-top: 1px dashed #dedede;
}
.sc__column__time__bg {
  height: 100%;
  background: #F5F5F5;
  width: 50px;
  padding: 0.25rem 0.5rem;
}
.filter-active {
  border-color: #779be7;
  box-shadow: 0 0px 5px #779be7;
}
.filter-inactive {
  opacity: 0.8;
}
